<template>
  <div
    :class="background ? 'ContactBG' : ''"
    class="ContactForm ConctactSection"
  >
    <h5 class="contactInfo" :style="textWhite ? 'color:white' : ''" v-if="text">
      {{ text }}
    </h5>
    <form @submit="onSubmit">
      <input
        v-model="form.name"
        type="text"
        class="ContactInput inputText"
        :placeholder="$t('ContactForm.Name')"
        required
      />
      <input
        v-model="form.email"
        type="email"
        class="ContactInput inputText"
        :placeholder="$t('ContactForm.Email')"
        required
      />
      <input
        v-model="form.phone"
        type="phone"
        class="ContactInput inputText"
        :placeholder="$t('ContactForm.Phone')"
        required
      />
      <select v-model="form.whenSell" class="dropdownForm inputText">
        <option disabled selected :value="null">
          {{ $t("ContactForm.When") }}
        </option>
        <option :value="$t('ContactForm.Option1')">
          {{ $t("ContactForm.Option1") }}
        </option>
        <option :value="$t('ContactForm.Option2')">
          {{ $t("ContactForm.Option2") }}
        </option>
        <option :value="$t('ContactForm.Option3')">
          {{ $t("ContactForm.Option3") }}
        </option>
      </select>
      <div class="agreement">
        <input
          type="checkbox"
          id="aggree"
          name="aggree"
          v-model="form.agreement"
          required
          class="agreementCheckbox"
        />
        <p class="agreementText">
          {{ $t("ContactForm.Agreement") }}
          <a href="" class="link">{{ $t("ContactForm.Privacy") }}</a> &
          <a href="" class="link">{{ $t("ContactForm.TermsOfUse") }}</a>
        </p>
      </div>
      <button v-if="!btnColor" class="SendButton navbarText" type="submit">
        {{ $t("Button.Send") }}
      </button>
      <button
        v-if="btnColor === 'primary'"
        class="SendButton primaryButton navbarText"
        type="submit"
      >
        {{ $t("Button.Send") }}
      </button>
      <button
        v-if="btnColor === 'white'"
        class="downloadButton navbarText"
        type="submit"
      >
        {{ $t("Button.Send") }}
      </button>
    </form>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "App",
  props: [
    "text",
    "btnColor",
    "background",
    "textWhite",
    "downloadUrl",
    "closeModal",
    "inscription",
  ],
  data: function () {
    return {
      form: {
        whenSell: null,
      },
      dismissSecs: 6,
      dismissCountDown: 0,
    };
  },
  methods: {
    ...mapActions(["postEmail"]),
    onSubmit(e) {
      e.preventDefault();
      let body = {
        subject: "Demande d'information",
        text: "",
      };
      if (this.inscription) {
        body.subject = "Inscription Infolettre";
      }
      if (this.downloadUrl) {
        body.subject = "Téléchargement de document";
        var link = document.createElement("a");
        // If you don't know the name or want to use
        // the webserver default set name = ''
        link.setAttribute("download", "document");
        link.setAttribute("target", "_blank");
        link.href = this.downloadUrl;
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      if (localStorage) {
        localStorage.setItem("user", true);
      }
      body.text += "Nom: " + this.form.name + "\n";
      body.text += "Courriel: " + this.form.email + "\n";
      body.text += "Téléphone: " + this.form.phone + "\n\n";
      body.text += "Quand: " + this.form.whenSell + "\n\n";
      // body.text += 'Message:\n\n';
      // body.text +=  this.form.message;
      this.postEmail(body);
      this.form = {};
      if (this.closeModal) {
        this.closeModal();
      }
    },
  },
};
</script>
<style scoped>
.arrow {
  color: black;
  display: absolute;
}
.downloadButton {
  display: inline-block;
  align-items: center;
  padding: 10px 20px;
  margin: auto;
  text-align: center;
  cursor: pointer;
  transition: all ease-out 0.2s;
  /* White */
  background: transparent;
  border: 1px solid;
  border-radius: 30px;
  color: var(--primary-dark-color);
  margin-top: 60px;
}
.downloadButton:hover {
  /* transform: scaleX(1.05) scaleY(1.04); */
  transform: scale(1.05);
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.2);
}
.Contact {
  align-items: flex-start;
  display: flex;
  background-color: var(--secondary-dark-color);
  min-height: 786px;
}
.primaryButton {
  background: var(--primary);
}
.ContactBG {
  background-color: rgba(255, 255, 255, 1) !important;
}
.ContactForm {
  background: transparent;
  border-radius: 2px;
  position: relative;
  width: 100%;
  /* max-width: 80%; */
  /* padding: 10px; */
  /* margin-bottom: 0px; */
}
select {
  background: url("../assets/dropdownArrow.svg");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
}
.form-control {
  padding: 5px 5px 5px 10px;
}

.dropdownSelect {
  position: relative;
}

.dropdownForm {
  width: 100%;
  padding: 5px 10px;
  border-radius: 2px 2px 0 0;
  border: 0px solid #ced4da;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  appearance: none;
  color: rgba(0, 0, 0, 0.6) !important;
  outline: none !important;
}

.dropdownArrow {
  position: absolute;
  right: 13px;
  top: 29%;
  pointer-events: none;
  color: black !important;
}
form {
  width: 100%;
}
.ContactInput {
  background-color: var(--white);
  border-radius: 2px;
  border: 1px solid #cdcdcd;
  margin-bottom: 10px;
  outline: 0 !important;
  padding: 10px;
  height: 40px;
  width: 100%;
}

.ContactInput:focus {
  outline: none !important;
}

/* .ConctactSection {
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
} */

.SendButton {
  width: 100%;
  color: var(--white);
}

.SendButton:hover {
  transform: translateY(-2px) !important;
  transition: all ease-out 0.3s !important;
}
.agreementCheckbox {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: var(--white);
  border: 1px solid #cdcdcd;
  margin-right: 10px;
  vertical-align: middle;
}
.agreementCheckbox:checked {
  background-color: var(--primary);
  border-color:   var(--primary);
}
.agreementCheckbox:hover {
  cursor: pointer;
}
.agreement {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.agreementText {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  margin: 0;
  text-align: left;
}
.link {
  text-decoration: none;
  color: var(--primary);
}
.link:hover {
  text-decoration: underline;
}
</style>
