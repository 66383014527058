<template>
  <div id="">
    <b-modal ref="downloadModal" centered hide-footer hide-header>
      <div>
        <h4 class="primary-color">{{$t("DownloadModal.title")}}</h4>
        <ContactForm :downloadUrl="downloadUrl" :closeModal="closeModal"/>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "",
  props: ['show','downloadUrl'],
  data: () => ({

  }),
  watch:{
    show(){
      if(localStorage && localStorage.getItem("user")){
        if(this.downloadUrl){
          var link = document.createElement("a");
          // If you don't know the name or want to use
          // the webserver default set name = ''
          link.setAttribute('download','document');
          link.setAttribute('target','_blank');
          link.href = this.downloadUrl;
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      }
      else{
        this.$refs['downloadModal'].show()
      }
    }
  },
  methods: {
    closeModal() {
      this.$refs['downloadModal'].hide()
    }
  }
}
</script>
<style scoped>
h4 {
  text-align: center;
}
</style>
