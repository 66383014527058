<template>
  <div>
    <a  href="tel:+14508448444" :class="color? 'phone-btn sidebar-color' : 'phone-btn'">
      <div class="phone"><b-icon icon="telephone-fill" aria-hidden="true"/>&nbsp;&nbsp;(450) 844-8444</div>
    </a>
  </div>
</template>
<script>
export default {
  name: "",
  props: ['primary', 'color'],
  data() {
    return {
      hover: false,
    };
  }
}
</script>
<style scoped>
.phone-btn {
    align-items: flex-start;
    border: 1px solid var(--primary-color);
    background-color: transparent;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.05em;
    border-radius: 25px;
    cursor: pointer;
    text-decoration: inherit;
    display: flex;
    height: 39px;
    margin-bottom: 1px;
    min-width: 187px;
    transition: all .5s ease;
    color: var(--secondary-dark-color);
}
.sidebar-color {
  color: var(--white) !important;
}

.phone-btn:hover {
    background-color: rgba(25, 25, 25, .6);
    color: var(--white);
}
.phone {
  align-self: center;
  text-decoration: inherit;
  margin: auto;
  min-height: 19px;
  min-width: 124px;
  text-align: center;
}

</style>
