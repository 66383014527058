<template>
  <div id="fronteval" class="Evaluation" data-id="207:17">
    <transition
      name="fade"
      mode="out-in"
    >
      <b-container  v-if="step === 1" key="step-1">
        <b-row class="step-1">
          <b-col class="primary-dark-color" offset-xl="6" xl="6">
            <b-row style="margin-bottom:30px; padding: 0px">
              <b-col>
                <h2>
                  {{$t("Evaluation.Title")}}
                </h2>
              </b-col>
            </b-row>
            <b-row class="Paragraph" style="margin-bottom:50px; ">
              <b-col>
                <p class="subText Paragraph">
                  {{$t("Evaluation.SubTitle")}}
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col >
                  <gmap-autocomplete @place_changed="initMarker" class="autocompleteInput imageShadow"></gmap-autocomplete>
                  <button class="SendButton-autocomplete navbarText white imageShadow" style="margin:0" type="submit" variant="primary" @click="stepSwitch(2)">{{$t("Button.Next")}}</button>
              </b-col>
            </b-row>
            <b-row>
              <b-col >
                <div>
                  <p class="subText Paragraph" style="color:red;" v-if="showError">
                    {{$t("Evaluation.Address")}}</p>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
      <b-container v-else-if="step === 2" key="step-2">
        <b-row class="step-2">
          <b-col class="primary-dark-color" offset-lg="6" lg="6">
            <b-row style="margin-bottom:30px">
              <!--<b-col>
                 <GoogleMaps :addresse="addresse"/>
              </b-col>-->
            </b-row>
            <b-row style="margin-bottom:10px">
              <b-col >
                <h3 >
                  {{$t("Evaluation.Step")}} 2
                </h3>
              </b-col>
            </b-row>
            <b-row style="margin-bottom:10px">
              <b-col>
                <h4>
                   {{$t("Evaluation.Confirm")}}
                </h4>
              </b-col>
            </b-row>
            <b-row>
              <b-col >
                <h5 v-if="addresse">
                  {{addresse.formatted_address}}
                </h5>
              </b-col>
            </b-row>
            <b-row class="btnRow">
                <button  class="primarySendButton evalBtnsFont" type="submit" variant="primary" @click="stepSwitch(1)">{{$t("Button.Restart")}}</button>
                <button class="secondarySendButton evalBtnsFont" type="submit" variant="primary" @click="stepSwitch(3)">{{$t("Button.Continue")}}</button>
            </b-row>
            <!-- <b-row class="loawdingRow">
              <b-col class="line active" cols="3">
              </b-col>
              <b-col class="line active" style="margin-left:10px" cols="3">
              </b-col>
              <b-col class="line" style="margin-left:10px" cols="3">
              </b-col>
            </b-row> -->
          </b-col>
        </b-row>
      </b-container>
      <b-container v-else-if="step === 3" key="step-3">
        <b-row>
          <b-col class="primary-dark-color" offset-lg="6" lg="6">
            <b-row style="margin-bottom:30px">
              <b-col>
                <!--<GoogleMaps :addresse="addresse"/>-->
              </b-col>
            </b-row>
            <b-row style="margin-bottom:10px">
              <b-col style="text-align:left">
                <h3>
                   {{$t("Evaluation.Step")}} 3
                </h3>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group>
                  <b-form-input
                    v-model="form.name"
                    type="text"
                    class="ContactInput"
                    :placeholder="$t('ContactForm.Name')"
                    aria-describedby="input-live-feedback"
                    :state="!error.name?null:false"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group>
                  <b-form-input
                    v-model="form.email"
                    type="email"
                    class="ContactInput"
                    :placeholder="$t('ContactForm.Email')"
                    :state="!error.email?null:false"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group>
                  <b-form-input
                    v-model="form.phone"
                    type="tel"
                    class="ContactInput "
                    :placeholder="$t('ContactForm.Phone')"
                    :state="!error.phone?null:false"
                    required
                  ></b-form-input>
                </b-form-group>
                <!-- <div>
                  <b-form-group>
                    <b-form-textarea
                      class="ContactInput input-message"
                      v-model="form.message"
                      :placeholder="$t('EvalComponent.Message')"
                      :state="!error.message?null:false"
                      rows="6"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-form-group>
                </div> -->
              </b-col>
            </b-row>
            <b-row class="btnRow">
                <button class="primarySendButton evalBtnsFont" type="submit" variant="primary" @click="stepSwitch(1)">{{$t("Button.Restart")}}</button>
                <button class="secondarySendButton evalBtnsFont" type="submit" variant="primary" @click="stepSwitch(4)">{{$t("Button.Send")}}</button>
            </b-row>
            <!-- <b-row class="loawdingRow">
              <b-col class="line padding-0 active" cols="3">
              </b-col>
              <b-col class="line padding-0 active" style="margin-left:10px" cols="3">
              </b-col>
              <b-col class="line padding-0 active" style="margin-left:10px" cols="3">
              </b-col>
            </b-row> -->
          </b-col>
        </b-row>
      </b-container>
      <b-container v-else-if="step === 4" key="step-4">
        <b-row>
          <b-col offset-lg="6" lg="6">
            <b-row style="margin-bottom:30px; justify-content: center">
              <div class="icon-complete" style="text-align:center">
                <img width="100%" height="100%" src="@/assets/complete.svg" alt="CheckMark when complete" class="svg262626">
              </div>
            </b-row>
            <b-row style="margin-bottom:30px">
              <b-col style="text-align:center">
                {{$t("Evaluation.Complete")}}
              </b-col>
            </b-row>
            <b-row style="margin-bottom:30px">
              <b-col style="width: 656px;text-align:center">
                <h3>
                  {{$t("Evaluation.CompleteMessage")}}
                </h3>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </transition>
  </div>
</template>

<script scoped>
//import GoogleMaps from "@/components/GoogleMaps.vue";
import { mapActions } from "vuex";
export default {
  name: "App",
  components:[
    //GoogleMaps
  ],
  data: function(){
    return {
      show: true,
      addresse:null,
      step:1,
      showError:false,
      error:{
        name:false,
        email:false,
        phone:false,
        message:false
      },
      form:{
        whenSell:null,
      }
    }
  },
  methods:{
    ...mapActions(["postEmail"]),
    onSubmit(event) {
      this.step++;
      console.log(this.step)
      event.preventDefault();
    },
    stepSwitch(step){
      if(step === 4){

        if(!this.form.name){
          this.error.name = true
        }
        if(!this.form.email){
          this.error.email = true
        }
        if(!this.form.phone){
          this.error.phone = true
        }


        if(
          !this.form.name ||
          !this.form.email ||
          !this.form.phone 
        )
        {
          return
        }

        let body = {
          subject: "Évaluation gratuite en ligne de votre propriété",
          text: "",
        };

        if(process.client && localStorage) {
          localStorage.setItem("user",true)
        }
        
        body.text += "Nom: " + this.form.name + "\n";
        body.text += "Courriel: " + this.form.email + "\n";
        body.text += "Téléphone: " + this.form.phone + "\n\n";
        body.text += "Adresse: " + this.addresse.formatted_address + "\n\n"
        // body.text += "Message:\n\n";
        // body.text += this.form.message;
        this.postEmail(body);
        this.step = step;
      }
      else{
        if(this.addresse && this.addresse!==null){
          this.step = step;
          this.showError = false;
        }
        else{
          this.showError = true;
        }
      }
    },
    initMarker(loc) {
      this.addresse = loc;
    },
  }
};
</script>

<style >

.col{
  text-align: left;
}

.Evaluation {
  padding: 20px 10px;
  width: 100%;
  min-height: 100vh;
  position: relative;
  align-items: flex-start;
  display: flex;
  background: url('https://api.agencequads.ca/uploads/Discover_chateauguay_evaluation_img_3c0853cd76.webp');
  background-size: auto 100%;
  background-repeat: no-repeat;
}


/* ----- STEP 1 ---- */
.step-1 {
  margin-bottom: 100px;
}
.autoCompleteBox{
  /* display: flex;
  justify-content: space-between; */
}

.subText {
  margin-left: 30px;
}

.autocompleteInput {
  border: 0px !important;
  height: 40px !important;
  max-width: 400px !important;
  min-width: 70% !important;
  font-family: 'Montserrat', sans-serif !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: calc(14px + (18 - 14) * ((100vw - 375px) / (1600 - 375)));
  line-height: 30px !important;
  color: var(--primary-dark-color) !important;
  border-radius: 5px 0px 0px 5px !important;
  padding: 0px 0px 0px 10px !important;
  margin-left: 30px;
  outline: none;
  display:inline-block ;
  float: left;
}

.SendButton-autocomplete {
  border-radius: 0px 5px 5px 0px !important;
  padding: 10px 20px !important;
  background-color: var(--primary-dark-color) !important;
  border: 0px !important;
  right: 65px !important;
  /* box-shadow: 18px 15px 30px 5px rgba(0, 0, 0, 0.05) !important; */
  display: inline-block;
  height: 40PX;
}

/* .picture {
  margin-top: 80px;
  height: 300px;
  width: 300px;
  margin-bottom: 30px;
  background-color: none;
  border-radius: 50%;
} */

.overlap-group {
  height: 39px;
  position: relative;
  width: 706px;
}


/* STEP 2 & 3 BUTTONS */

.step-2 {

}

.map-view {

}

.btnRow {
  margin-top: 50px;
  padding-right: 15px;
  padding-left: 15px;
}

.evalBtnsFont {
  font-family: 'Montserrat', sans-serif;
  font-size: calc(14px + (16 - 14) * ((100vw - 375px) / (1600 - 375)));
  text-transform: uppercase !important;
  letter-spacing: 0.05em;
  font-stretch: normal;
  font-weight: 400;
}

.primarySendButton{
    padding: 10px 30px;
    margin-right: 40px;
    cursor: pointer;
    color: var(--primary-dark-color);
    background-color: transparent;
    border: 1px solid var(--primary-dark-color);
    border-radius: 30px;
    height: fit-content;

}

.primarySendButton:hover, .secondarySendButton:hover {
  transform: translateY(-3px);
  transition: all ease-out .3s;
}

.secondarySendButton {
    padding: 10px 30px;
    cursor: pointer;
    border: 1px solid var(--primary-dark-color);
    border-radius: 30px;
    background-color: var(--primary-dark-color);
    color: var(--white);
    height: fit-content;
}

.text-2 {
  color: var(--secondary-dark-color);
}

.ContactInput {
  font-family: 'Montserrat', sans-serif !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: calc(12px + (18 - 12) * ((100vw - 375px) / (1600 - 375)));
  line-height: 30px !important;
}

.input-message {
  max-height: 80px;
}

/* ---- loading line ---- */

.loawdingRow {
  margin-top: 50px;
  padding-left: 15px;
}

.line {
  background-color: var(--primary-dark-color);
  border-radius: 5px;
  height: 4px;
  margin-top: 17px;
  width: 100%;
  opacity: 0.5;
}
.active{
  opacity: 1;
}

/* ---- Step 4 ----- */

.icon-complete {
  width: 50px;
}

</style>
<style scoped>

@media (max-width: 768px) {

  h3, h4, h5 {
    text-align: center;
  }

  .Evaluation {
    min-height: 800px;
    background: linear-gradient(254.48deg, #FFFFFF 55.03%, rgba(0, 0, 0, 0) 88.7%, rgba(255, 255, 255, 0) 88.7%), url(https://api.agencequads.ca/uploads/living_Room_gradient_f2e012600d.png);
}

  .SendButton-autocomplete {
    font-size: 14px !important;
    padding: 10px 10px !important;
  }

  .autocompleteInput {
    margin-left: 0px;
    min-width: 70% !important;
  }

  .subText {
    margin-left: 0px;
  }

  .ContactForm {
    padding: 0px !important;
    position: absolute;
    width: 100% !important;
  }

  .primarySendButton {
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .primarySendButton, .secondarySendButton {
    min-width: 75vw;
    letter-spacing: .4em;
  }

  .btnRow {
    justify-content: center;
  }

  .loawdingRow {
    justify-content: center;
    padding-left: 0px;
  }

}

/* ---- ANIMATION ---- */

.animation-1{
  animation: moveIn-left 1s ease-in 0s 1 ;
}
.animation-2{
  animation: zoomIn 0.9s ease-in 0s 1 ;
}

.fade-enter-active{
  animation: fadeInDown; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 0.5s; /* don't forget to set a duration! */
}

.fade-leave-active {
  animation: fadeOutUp; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 0.5s; /* don't forget to set a duration! */
}


</style>
