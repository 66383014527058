<template>
  <div id="relation" class="Information" data-id="207:17">
    <div class="info-box">
      <div class="info-box-wrapper">
        <h3 class="text">{{$t('Information.Title')}}</h3>
        <ContactForm :btnColor="'white'" />
      </div>
    </div>
    <div class="info-box">
      <div class="info-img">
        <img src="https://api.agencequads.ca/uploads/Discover_chateauguay_info_img_dad220df72.webp" alt="image information chateauguay">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data: function () {
    return {
      menuTitle: {},
      form: {
        whenSell: null,
      },
      dismissSecs: 6,
      dismissCountDown: 0,
      options: [
        { value: 0, text: "0 à 3 mois" },
        { value: 1, text: "3 à 6 mois" },
        { value: 2, text: "6 mois et +" },
      ],
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
    },
  },
};
</script>

<style scoped>

.Information {
  display: flex;
  background-color: var(--white);
  width: 100%;
}
.info-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100%/2);
  height: 650px;
}
.info-box-wrapper {
  width: 100%;
  max-width: 500px;
  padding: 20px;
}
.info-img {
width: 100%;
height: 100%;
}
.info-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
@media (max-width: 900px) {
  .Information {
    flex-direction: column;
  }
  .info-box {
    width: calc(100%);
  }
}
</style>
