<template>
  <button id="lang" :class="color? 'langButton color-mobile' : 'langButton'" @click="toggleLang()">fr</button>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "",
  props: [
    'color'
  ],
  components: {},
  data: () => ({

  }),
  computed: {
    ...mapState({
      lang: (state) => state.lang.value,
    })
  },
  methods: {
    ...mapActions([
      'setLangValue'
    ]),
    toggleLang(){
      var x = document.getElementById("lang");
           if (x.innerHTML === "EN") {
        x.innerHTML = "FR";
        this.$i18n.locale = 'en';
        this.setLangValue('en')
      } else {
        x.innerHTML = "EN";
        this.setLangValue('fr')
        this.$i18n.locale = 'fr';
      }
    }
  },
  mounted() {
    this.$i18n.locale = this.lang;

  }
}
</script>
<style>
.langButton {
    margin: auto;
    background: transparent;
    border: 1px solid var(--primary-color);
    padding: 5px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: var(--secondary-dark-color);
    transition: all ease-out .3s;
    min-width: 40px;
}
.color-mobile {
  color: var(--white) !important;
}
.langButton:hover {
  background-color: rgba(25, 25, 25, .60);
  color: var(--white);
}

</style>
