<template>
  <div :class="coverUrl? 'doccard doccard-shadow ':'doccard'">
    <img v-if="!coverUrl" class="imgCover" :src="doc">
    <div v-if="!empty" class="section">
      <div :class="coverUrl?'textbox':'textbox2'">
        <h3 class="text">{{title}}</h3>
        <h5 class="text">{{text}}</h5>
        <a v-if="coverUrl" @click="Download">
          <div class="downloadButton Navigation-text-btn">
            {{$t ('Button.Download')}}
          </div>
        </a>
      </div>
    </div>
    <DownloadModal :show="show" :downloadUrl="downloadUrl"/>
  </div>
</template>
<script>
export default {
  name: "",
  props: ['coverUrl', 'downloadUrl', 'text', 'empty', 'title', 'doc'],
  data: () => ({
    show:false
  }),
  methods: {
    Download() {
      this.show = !this.show
    }
  }
}
</script>
<style scoped>
.doccard{
  position: relative;
  width: 100%;
  min-height: 38vw;
  margin: auto;
  overflow: hidden;
  align-items: flex-start;
  display: flex;
  background-color: var(--white);
}
.doccard-shadow {
    box-shadow: inset -4px -4px 20px 5px rgba(0, 0, 0, 0.01), inset 4px 4px 20px 10px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset -4px -4px 20px 5px rgba(0, 0, 0, 0.01), inset 4px 4px 20px 10px rgba(0, 0, 0, 0.05);
}

.section{
  padding: 20px;
  margin: auto;
}
.imgCover{
  /* position: absolute; */
  object-fit: contain;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.textbox{
  position: relative;
  text-align: center;
  max-width: 650px;
  padding: 20px;
  z-index: 1;
  border-radius: 5px;
  color: var(--primary-dark);
  transition: all ease-out .2s;
}

.textbox2{
  text-align: center;
  padding: 30px;
  z-index: 10;
}
.text{
  margin: 30px 0px;
  text-align: center;
  max-width: 595px;
}


</style>
