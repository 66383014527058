<template>
  <div class="page-container">
      <div v-scrollanimation-1 class="page-wrapper">
        <div>
          <h2>
              {{ $t ("Map.title")}}
          </h2>
          <h5>
              {{ $t ("Map.text")}}
          </h5>
        </div>
        <!-- <img src="" alt=""> -->
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63884.536751914995!2d-73.73616764927178!3d45.352406825366586!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc9150bf002489f%3A0x6552f8359b6df45e!2sCh%C3%A2teauguay%2C%20QC!5e0!3m2!1sen!2sca!4v1667406263245!5m2!1sen!2sca" width="100%" height="700" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
  </div>
</template>

<script>
export default {
  name: "App",
  props: [],
  data: function() {
    return {}
  },
  methods: {
  }
};
</script>

<style scoped>
.page-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
    width: 100%;
    height: 100%;
    background: var(--white);
}

.page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    max-width: 1200px;
    height: 800px;
} 

</style>
