<template>
  <div class="home">
    <Presentation />
    <Evaluation />
    <BlackBox customText="show" :text="$t('BlackBox.bio')" />
    <BGuide />
    <SGuide />
    <Testimonial />
    <Information />
  </div>
</template>

<script>
// @ is an alias to /src
import Presentation from "@/components/Presentation";
import Evaluation from "@/components/Evaluation";
import BlackBox from "@/components/BlackBox";
import BGuide from "@/components/BGuide";
import SGuide from "@/components/SGuide";
import Testimonial from "@/components/Testimonial";
import Information from "@/components/Information";
export default {
  name: "Home",
  components: {
    Presentation,
    Evaluation,
    BlackBox,
    BGuide,
    SGuide,
    Testimonial,
    Information
  },

};
</script>
