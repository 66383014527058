<template>
  <div class="body-container">
    <div class="boxList-row">
      <div class="boxList">
        <h3 class="boxList-title">{{$t("DiscoverBody.title")}}</h3>
        <div class="boxList-list-row">
          <ul class="paragraph">
            <li>Lac Saint-Louis</li>
            <li>Dorval</li>
            <li>Lachine</li>
            <li>Fleuve Saint-Laurent</li>
            <li>Léry</li>
          </ul>
          <ul class="paragraph">
            <li>Kahnawake</li>
            <li>Beauharnois</li>
            <li>Sainte-Martine</li>
            <li>Mercier</li>
            <li>Saint-Isidore</li>
          </ul>
        </div>
      </div>
      <div class="boxList">
        <h3 class="boxList-title">{{$t("DiscoverBody.title-1")}}</h3>
        <ul class="paragraph">
          <li>{{$t("DiscoverBody.text-1")}}</li>
          <li>{{$t("DiscoverBody.text-2")}}</li>
          <li>{{$t("DiscoverBody.text-3")}}</li>
          <li>{{$t("DiscoverBody.text-4")}}</li>
        </ul>
      </div>
    </div>
    <div class="card-row">
      <div v-for="(card, i) of  cards" :key="i" class="card-content">
        <h3 class="card-content-title">{{card.title}}</h3>
        <div class="card-content-body">
          <p class="paragraph" v-for="(list, index) in  card.lists" :key="index">
            {{list}}
          </p>
        </div>
      </div>
    </div>
    <div class="boxList-row">
      <div class="boxList">
        <h3 class="boxList-title">{{$t("DiscoverBody.title-2")}}</h3>
        <ul class="paragraph">
          <li>{{$t("DiscoverBody.text-5")}}</li>
          <li>{{$t("DiscoverBody.text-6")}}</li>
          <li>{{$t("DiscoverBody.text-7")}}</li>
        </ul>
      </div>
      <div class="boxList">
        <h3 class="boxList-title">{{$t("DiscoverBody.title-3")}}</h3>
        <ul class="paragraph">
          <li>{{$t("DiscoverBody.text-8")}}</li>
          <li>{{$t("DiscoverBody.text-9")}}</li>
          <li>{{$t("DiscoverBody.text-10")}}</li>
          <li>{{$t("DiscoverBody.text-11")}}</li>
        </ul>
      </div>
    </div>
    <div class="linear-bg" />
  </div>
</template>

<script>
export default {
  name: "DiscoverBody",
  props: [
    'title',
    'text',
    'icon'
  ],
  data: function () {
    return {

    };
  },
  computed: {
    cards(){
      return[        
        { 
         title: this.$t('DiscoverBody.card-1'),
         lists: [
            this.$t('DiscoverBody.point-1'),
            this.$t('DiscoverBody.point-2'),
            this.$t('DiscoverBody.point-3'),
            this.$t('DiscoverBody.point-4'),
            this.$t('DiscoverBody.point-5'),
            this.$t('DiscoverBody.point-6'),
            this.$t('DiscoverBody.point-7'),
           ] ,
        },
        { 
         title: this.$t('DiscoverBody.card-2'),
         lists: [
           this.$t('DiscoverBody.point-8'),
            this.$t('DiscoverBody.point-9'),
            this.$t('DiscoverBody.point-10'),
            this.$t('DiscoverBody.point-11'),
           ] ,
        },
        { 
         title: this.$t('DiscoverBody.card-3'),
         lists: [
            this.$t('DiscoverBody.point-12'),
            this.$t('DiscoverBody.point-13'),
            this.$t('DiscoverBody.point-14'),
            this.$t('DiscoverBody.point-15'),
            this.$t('DiscoverBody.point-16'),
           ] ,
        },
      ]
    }
  }
};
</script>

<style scoped>
.body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 80px;
  width: 100%;
  min-height: 1800px;
  height: fit-content;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.55), rgba(255, 255, 255, 0.55)), url('https://api.agencequads.ca/uploads/Discover_list_bg_img_6940bef626.webp');
  /* background: url('https://api.agencequads.ca/uploads/Discover_list_bg_img_6940bef626.webp'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  font-family: "Montserrat";
  padding: 50px 20px;
  position: relative;
}
.linear-bg {
  position: absolute;
  background: linear-gradient(267.01deg, #FFFFFF 43.65%, rgba(255, 255, 255, 0) 87.84%);
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 1;
}
.boxList-row,
.card-row {
  display: flex;
  flex-wrap: wrap;
  z-index: 2;
}
.card-row {
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  gap: 60px;
  padding: 60px;
}
.card-content {
  border-top: 10px solid var(--primary-color);
  text-align: center;
  background: rgba(255, 255, 255, 0.5);
  color: var(--primary-dark-color);
  width: 400px;
  min-width: 350px;
  height: 600px;
  border-radius: 2px;
  box-shadow: 0px 15px 30px 5px rgba(0, 0, 0, 0.15);
}
.card-content-body  {
  width: 100%;
  height: 100%;
  padding: 10px;
}
.card-content-title {
  color: var(--primary-color);
  margin: 20px 0px 0px;
}
.card-content-body p {
  text-align: center;
  margin: 30px 0px;
}
.card-content-body p,
.boxList ul  {
  font-size: 20px;
}
.boxList-row {
  justify-content: center;
  gap: 100px;
}
.boxList {
  max-width: 100%;
  max-width: 600px;
  color: var(--primary-dark-color);
}
.boxList-title {
  text-align: left;
}
.boxList ul li {
  margin: 20px 0px;
  line-height: 35px;
  text-align: left;
}
.boxList-list-row {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

@media (max-width: 900px) {

}
</style>
