<template>
  <div id="contact" class="Contact">
    <b-container>
      <b-row>
        <b-col md="6">
          <a href="/"><img class="footerLogo" width="300px" src="https://api.agencequads.ca/uploads/Chateauguay_Logo_efc8b9b446.png"/></a>
          <p class="paragraphe footerText">For everything real estate</p>
          <p @click="$changePage('fronteval')" class="paragraphe nav-footer">
            {{ $t("NavBar.Evaluation") }}
          </p>
          <p @click="$changePage('BuyerGuide')" class="paragraphe nav-footer">
            {{ $t("NavBar.Buy") }}
          </p>
          <p @click="$changePage('SellerGuide')" class="paragraphe nav-footer">
            {{ $t("NavBar.Sell") }}
          </p>
          <p @click="$changePage('BuyerGuide')" class="paragraphe nav-footer">
            {{ $t("NavBar.Discover") }}
          </p>
          <p @click="$changePage('Magazine')" class="paragraphe nav-footer">
            {{ $t("NavBar.Magazine") }}
          </p>
        </b-col>
        <b-col md="6">
          <ContactForm :text="$t('ContactForm.ContactTitle')" :btnColor="'primary'" :textWhite="false" />
          <b-row class="socialMedia-row">
            <b-col cols="2" >
              <a href="https://www.facebook.com/ImmobilierChateauguay" target="blank_">
                <img src="@/assets/facebook.svg" class="svgDark" />
              </a>
            </b-col >
            <b-col cols="2">
              <a href="" target="blank_">
                <img src="@/assets/Instagram-logo.svg" class="svgDark" />
              </a>
            </b-col>
            <!-- <b-col cols="6">
            </b-col> -->
            <!-- <b-col cols="3">
              <img src="@/assets/twitter.svg" class="svgWhite" />
            </b-col>
            <b-col cols="3">
              <img src="@/assets/linked_in.svg" class="svgWhite" />
            </b-col>
            <b-col cols="3">
              <img src="@/assets/youtube.svg" class="svgWhite" />
            </b-col> -->
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "App",
  data: function() {
    return {
      form:{},
      dismissSecs: 6,
      dismissCountDown: 0,
    }
  },
  methods: {
    ...mapActions([
      "postEmail"
    ]),
    onSubmit(e) {
      e.preventDefault();
      let body = {
        subject:"Demande d'information",
        text:''
      }
      body.text += 'Nom: '+ this.form.name + '\n';
      body.text += 'Courriel: '+ this.form.email + '\n';
      body.text += 'Téléphone: '+ this.form.phone + '\n\n';
      body.text += 'Message:\n\n';
      body.text +=  this.form.message;
      this.postEmail(body)
      this.form = {}
    }
  }
};
</script>

<style>
.Contact {
  align-items: flex-start;
  display: flex;
  background-color: var(--white);
  color: var(--secondary-dark-color);
  min-height: 786px;
}

.footerLogo{
  margin-top: 20px;
  margin-bottom: 20px;
}
.footerText{
  opacity: 0.5;
}

.nav-footer{
    margin-top: 40px;
    cursor: pointer;
    text-transform: uppercase;
    color: var(--secondary-dark-color);
}

.contact_logo{
  position: absolute;
  max-width: 200px;
  top: 30px;
  right: 30px;
}

.ContactForm {
  background-color: var(--primary-light-color);
  border-radius: 2px;
  position: relative;
  width: 335px;
  padding: 10px;
  margin-bottom: 0px;
}

.ContactInfo {
  margin-top: 100px;
}

.ContactInput {
  background-color: var(--primary-color);
  border-radius: 2px;
  border: 1px solid #CDCDCD;
  margin-bottom: 20px
}

.ConctactSection {
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.ContactLeftImage{
  position: relative;
  background-image: url("https://admin.rhpanda.ca/uploads/Tablepic_min_7ed59b3a90.png");
  background-position: 50% 50%;
  background-size: cover;
  height: 614px;
  width: 100%;
}

.security-Icon {
  width: 315px;
  height: 355px;
}

.socialMedia-row {
  justify-content: space-evenly !important;
  margin-top: 40px !important;
  margin-bottom: 20px !important;
  padding: 0px !important;
  
}

</style>
