import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from "./lang/i18n";

import * as VueGoogleMaps from "vue2-google-maps" // Import package

import VueCarousel from 'vue-carousel';
import ScrollAnimation1 from './directives/ScrollAnimation1'
import ScrollAnimation2 from './directives/ScrollAnimation2'
import VueAnimateOnScroll from 'vue-animate-onscroll'

Vue.use(VueAnimateOnScroll)
Vue.directive("scrollanimation1", ScrollAnimation1);
Vue.directive("scrollanimation2", ScrollAnimation2);
Vue.directive("scrollanimation3", ScrollAnimation2);

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'



Vue.config.productionTip = false
import "./assets/style/Keyframes_kit.css";
import "./assets/style/style.css";
import 'animate.css';

import PhoneButton from '@/components/PhoneButton'
import LangButton from '@/components/LangButton'
import ContactForm from '@/components/ContactForm'
import DownloadModal from '@/components/DownloadModal'
import Signature from '@/components/Signature'

Vue.component('PhoneButton', PhoneButton)
Vue.component('LangButton', LangButton)
Vue.component('ContactForm', ContactForm)
Vue.component('DownloadModal', DownloadModal)
Vue.component('Signature', Signature)

import VueFbCustomerChat from '@/helpers/fbchatbox.js'

Vue.use(VueFbCustomerChat, {
  page_id: 108455394943709, //  change 'null' to your Facebook Page ID,
  theme_color: '#d6ad7f', // theme color in HEX
})

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCeDrj6crKVNCMppveiPcxJi_iLBBHPvt0',
        libraries: "places"
    },
    installComponents: true
});

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
    // Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueCarousel);
new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')

Vue.prototype.$changePage = async function(page) {
    if (
        page.indexOf('front') !== -1
    ) {

        this.$router.push({
            name: "Home",
        });

        await this.$nextTick();

        let id = document.getElementById(page);

        id.scrollIntoView();
    } else {
        this.$router.push({
            name: page,
        });
        window.scrollTo(0, 0);
    }
};
