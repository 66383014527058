<template>
  <div class="page">
    <SGuide />
    <PriceForm />
    <!-- <WhiteBox /> -->
  </div>
</template>
<script>
// import WhiteBox from "@/components/WhiteBox";
import SGuide from "@/components/SGuide";
import PriceForm from "@/components/PriceForm";
export default {
  name: "",
  components: {
   // WhiteBox,
    SGuide,
    PriceForm
  },
  data: () => ({

  })
}
</script>
<style scoped>
</style>
