<template>
  <div id="fronttestimonial" class="testimonial" data-id="207:17">
    <div class="testiCarousel">
      <carousel
        :perPage="1"
        :autoplay="true"
        :navigationEnabled="true"
        :autoplayTimeout="10000"
        :loop="true"
        :paginationEnabled="false"
        :navigation-next-label="navigationNext"
        :navigation-prev-label="navigationPrev"
      >
        <slide v-for="(testimonial, index) in testimonials" :key="index">
          <TestimonialCard :value="testimonial" />
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
  import TestimonialCard from "@/components/TestimonialCard";
  import ChevronRight from '@/assets/right.svg';
  import ChevronLeft from '@/assets/left.svg';
  export default {
    components: {
      TestimonialCard
    },
    data() {
      return {
      }
    },
    methods: {
    },
    computed: {
      testimonials(){
        return [
          {
            name:"Narcisse Awono",
            text: this.$t("Testimonial.Text1")
          },
          {
            name:"Julie Bergeron",
            text:this.$t("Testimonial.Text2")
          },
          {
            name:"Thomas Rondeau",
            text:this.$t("Testimonial.Text3")
          }
        ]
      },
      navigationNext() {
        const chevronRight = ChevronRight;
        return `<img src="${chevronRight}" class="svgWhite"/>`;
      },
      navigationPrev() {
        const chevronLeft = ChevronLeft;
        return `<img src="${chevronLeft}" class="svgWhite"/>`;
      }
    }
  }
</script>

<style >
.testimonial {
  align-items: flex-start;
  display: flex;
  background-color: var(--secondary-dark-color);
  min-height: 600px;
}

.testiCarousel {
  margin: auto;
  max-width: 680px;
}
.testquote {
  display: flex;
  justify-content: space-between;
  width: 166px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 20px;
  text-align: left;
}
.testiquote {
  color: var(--white);
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 20px;
  text-align: left;
}
.testiquotelast {
  padding-left: 50px;
  padding-right: 50px;
  text-align: left;
}
@media (max-width: 768px) {
  .testimonial {
    padding: 80px 0px;
  }
  .testiCarousel {
    margin: auto;
    width: 80%;
  }
  .testquote {
    width: 86px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0;
    padding-bottom: 20px;
    text-align: left;
  }
  .testiquote {
    padding-left: 10px;
    padding-right: 10px;
    margin: 0;
    padding-bottom: 20px;
    text-align: left;
  }
  .testiquotelast {
    padding-left: 10px;
    padding-right: 10px;
    text-align: left;
  }
  /deep/.VueCarousel-navigation-next {
    right: 0 !important;
  }
  /deep/.VueCarousel-navigation-prev {
    left: 0 !important;
  }
}
</style>
<style scoped>
.animation-1 {
  animation: moveIn-right 1s ease-in 0s 1;
}
.animation-2 {
  animation: moveIn-left 1s ease-in 0s 1;
}
</style>
