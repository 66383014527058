<template>
  <div class="background" id="">
    <transition
      name="fade"
      mode="out-in"
    >
    <b-container v-if="step === 1" key="step-1">
    <div class="section-wrapper">
      <h3 class="content-title">{{ $t('PriceForm.Title') }}</h3>
      <img class="space-top" src="@/assets/Frame 90.svg"/>
      <h4 class="space-top space-bottom">1. {{ $t('PriceForm.Step1') }}</h4>
      <b-row align-v="center">
        <b-col >
          <div class="">
            <gmap-autocomplete @place_changed="initMarker" class="autocompleteInput imageShadow"></gmap-autocomplete>
            <p style="color:red;" v-if="showError">{{ $t('Evaluation.Error') }}</p>
          </div>
        </b-col>
      </b-row>
      <h4 class="space-top space-bottom" >2. {{ $t('PriceForm.Step2') }}</h4>
      <div class="form-wrapper">
    
            <input
                v-model="form.name"
                type="text"
                class="ContactInput inputText"
                :placeholder="$t('ContactForm.Name')"
                required
            />
            <input
                v-model="form.email"
                type="email"
                class="ContactInput inputText"
                :placeholder="$t('ContactForm.Email')"
                required
            />
            <input
                v-model="form.phone"
                type="phone"
                class="ContactInput inputText"
                :placeholder="$t('ContactForm.Phone')"
                required
            />
            <select v-model="form.whenSell" class="dropdownForm inputText">
                <option disabled selected :value="$t('ContactForm.When')">{{$t('ContactForm.When')}}</option>
                <option :value="$t('ContactForm.Option1')">
                  {{$t('ContactForm.Option1')}}
                </option>
                <option :value="$t('ContactForm.Option2')">
                  {{$t('ContactForm.Option2')}}
                </option>
                <option :value="$t('ContactForm.Option3')">
                  {{$t('ContactForm.Option3')}}
                </option>
            </select>
            <button class="SendButton navbarText"  @click="stepSwitch(2)" type="submit">{{$t('Button.Send')}}</button>
        
      </div>
    </div>
    </b-container>
     <div v-else-if="step === 2" key="step-2">
      <div class="section-complete">
       
        <div class="icon-complete" style="text-align:center">
          <img width="100%" height="100%" src="@/assets/complete.svg" alt="CheckMark when complete" class="svg262626">
        </div>
          <h4>
             {{$t("Evaluation.Complete")}}
          </h4>     
          <h3>
            {{$t("PriceForm.CompleteMessage")}}
          </h3>
        </div>
      </div>
      </transition>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "",
  data: function(){
    return {
      form:{ },
      addresse:null,
      showError:false,
      step:1,
      error:{
        name:false,
        email:false,
        phone:false,
        whenSell: false
      },
    }
  },
  methods: {
      ...mapActions(["postEmail"]),
    onSubmit(event) {
      this.step++;
      console.log(this.step)
      event.preventDefault();
    },
    stepSwitch(step){
      if(step === 2){

        if(!this.form.name){
          this.error.name = true
        }
        if(!this.form.email){
          this.error.email = true
        }
        if(!this.form.phone){
          this.error.phone = true
        }
        if(!this.form.whenSell){
          this.error.whenSell = true
        }
        if(
          !this.form.name ||
          !this.form.email ||
          !this.form.phone ||
          !this.form.whenSell 
        )
        {
          return
        }

        let body = {
          subject: "Demande de le prix de vente d’une maison ",
          text: "",
        };
        if(process.client && localStorage) {
          localStorage.setItem("user",true)
        }
        body.text += "Nom: " + this.form.name + "\n";
        body.text += "Courriel: " + this.form.email + "\n";
        body.text += "Téléphone: " + this.form.phone + "\n\n";
        body.text += "Adresse: " + this.addresse.formatted_address + "\n\n"
        // body.text += "Message:\n\n";
        // body.text += this.form.message;
        this.postEmail(body);
        this.step = step;
      }
      else{
        if(this.addresse && this.addresse!==null){
          this.step = step;
          this.showError = false;
        }
        else{
          this.showError = true;
        }
      }
    },
    initMarker(loc) {
      this.addresse = loc;
    },
  }
}
</script>
<style scoped>
.background {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 1350px;
  background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.55), rgba(255, 255, 255, 0.55)), url("https://api.agencequads.ca/uploads/Woman_living_Room_4ff2569042.png");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.section-complete {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.section-wrapper{
  margin: auto;
}

.content-title {
  max-width: 100%;
}

.space-top {
  margin-top: 50px;
}

.space-bottom {
  margin-bottom: 50px;
}
.form-wrapper {
  max-width: 335px;
  margin: auto;
}
.autocompleteInput, .autocompleteInput:focus {
  border: none;
  outline: none;
  height: 40px;
  width: 100%;
  max-width: 400px;
  padding: 10px;
  border-radius: 5px !important;
  float: none !important;
  /* margin: auto !important; */
}
.ContactInput {
  background-color: var(--white);
  border-radius: 2px;
  border: 1px solid #CDCDCD;
  margin-bottom: 10px;
  outline: 0 !important;
  padding: 10px;
  height: 40px;
  width: 100%;
}

.ContactInput:focus { 
  outline: none !important; 
}
.dropdownForm {
  width: 100%;
  padding: 5px 10px;
  border-radius: 2px 2px 0 0;
  border: 0px solid #ced4da;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  appearance: none;
  color: rgba(0, 0, 0, 0.6) !important;
  outline: none !important;
}
select {
  background: url('../assets/dropdownArrow.svg');
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%

}
.SendButton{
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  width: 100%;
  background: var(--primary-dark-color) !important;
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.2) !important;
  border-radius: 5px !important;
  border-color: var(--primary-dark-color) !important;
  margin-top: 20px;
  text-transform: uppercase;
  padding: 10px 20px;
  color: var(--white);
}
.col {
  text-align: center !important;
}

@media (max-width: 768px) {
  .section-wrapper {
    padding: 10px !important;
  }

  .autocompleteInput {
    width: 90vw !important;
  }

}
.fade-enter-active{
  animation: fadeInDown; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 0.5s; /* don't forget to set a duration! */
}

.fade-leave-active {
  animation: fadeOutUp; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 0.5s; /* don't forget to set a duration! */
}

</style>
