<template>
  <div id="app">
    <b-alert
      :show="dismissCountDown"
      class="alerbox"
      :variant="alert.type"
      :fade="true"
      @dismissed="dismissCountDown=0"
      @dismiss-count-down="countDownChanged"
    >
      <p >{{alert.message}}</p>
    </b-alert>
    <NavBar />
    <router-view/>
    <Footer />
    <Signature />
    <b-modal style="padding: 20px;" ref="infoModal" centered hide-footer hide-header>
      <div style="text-align: center;">
        <h5 class="primary-color">{{$t("ModalForm.title")}}</h5>
        <p class="paragraph primary-dark-color">{{$t("ModalForm.text")}}</p>
        <ContactForm :inscription="true" :closeModal="closeModal" style="margin-left: 0px; padding: 0px; margin-top: 50px;"/>
      </div>
    </b-modal>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue"
import Footer from "@/components/Footer";
import { mapState } from "vuex";
export default {
  components:{
    NavBar,
    Footer
  },
  data: function() {
    return {
      dismissSecs: 6,
      dismissCountDown: 0,
      modalParam:{

      }
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
      alertToggle: (state) => state.alert.toggle
    }),
  },
  watch: {
    alertToggle() {
      this.dismissCountDown = this.dismissSecs
    }
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showInfoModal() {
      if(localStorage && !localStorage.getItem("user")){
        const self = this;
        setTimeout(function(){
          self.$refs['infoModal'].show()
        }, 5000);
        //this.$refs['infoModal'].show()
      }
    },
    closeModal() {
      this.$refs['infoModal'].hide()
    }
  },
  mounted(){
    this.showInfoModal()

    window.scrollTo(0, 0);
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.page{
  padding-top: 70px;
  min-height: 100vh
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: white;
}

.modal-body {
  padding: 1.23rem 1.875rem 1.875rem 1.875rem !important;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

@-webkit-keyframes fadeinout {
  0%,100% { opacity: 0; }
  50% { opacity: 1; }
}

@keyframes fadeinout {
  0%,100% { opacity: 0; }
  50% { opacity: 1; }
}

</style>
