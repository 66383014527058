<template>
  <div class="header-container">
    <div class="header-title">
      <h2>{{$t("DiscoverHeader.title")}}</h2>
      <h4 class="header-title-text">{{$t("DiscoverHeader.text")}}</h4>
    </div>
    <div class="header-list">
      <div class="header-box" v-for="(list, index) in  box" :key="index">
        <img  :src="list.icon" alt="">
        <p class="header-box-title">{{list.title}}</p>
        <p class="header-box-text">{{list.text}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DiscoverHeader",
  props: [
    'title',
    'text',
    'icon'
  ],
  data: function () {
    return {
    };
  },
  computed: {
    box(){
      return [
        {
          title: this.$t('DiscoverHeader.title-1'),
          text: "Montérégie",
          icon: 'https://api.agencequads.ca/uploads/location_icon_discover_81e7f48b09.svg'
        },
        {
          title: this.$t('DiscoverHeader.title-2'),
          text: "3 novembre 1855",
          icon: 'https://api.agencequads.ca/uploads/edit_icon_discover_7da486c7b3.svg'
        },
         {
          title: this.$t('DiscoverHeader.title-3'),
          text: "49 050 hab",
          icon: 'https://api.agencequads.ca/uploads/people_icon_discover_b5a52b7e82.svg'
        },
        {
          title: this.$t('DiscoverHeader.title-4'),
          text: "35,4 km²",
          icon: 'https://api.agencequads.ca/uploads/zoom_icon_discover_774e1876e8.svg'
        }
      ]
    }
  }
};
</script>

<style scoped>
.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  width: 100%;
  min-height: 700px;
  background-color: var(--secondary-dark-color);
  color: var(--white);
  text-align: center;
  font-family: "Montserrat";
  padding: 20px;
}
.header-title {
  max-width: 80%;
}
.header-title-text{
  opacity: 0.8;
}
.header-list {
  display: flex;
  justify-content: space-evenly;
  gap: auto;
  flex-wrap: wrap;
  max-width: 1100px;
  width: 100%;
}
.header-box {
  width: 100%;
  max-width: 250px;
}
.header-box-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0em;
}
.header-box-text {
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0em;
  opacity: 0.7;
}
@media (max-width: 900px) {

}
</style>
