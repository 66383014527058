<template>
  <div id="">
    <div class="testquote primary-color">
      <img src="../assets/quote.svg" alt=""  class="svgPrimary" slyle="margin-right:10px !important">
      <img src="../assets/quote.svg" alt=""  class="svgPrimary">
    </div>
    <div class="testiquote paragraph">
      {{value.text}}
    </div>
    <div class="testiquotelast paragraph primary-color">
      {{value.name}}
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  props: ['value'],
  data: () => ({

  })
}
</script>

