<template>
  <div class="page">
    <DiscoverHeader />
    <DiscoverBody />
    <BlackBox  customText="show" :title="$t('BlackBox.title-1')" :text="$t('BlackBox.text-1')"  />
    <Map />
  </div>
</template>
<script>
import DiscoverHeader from "@/components/DiscoverHeader";
import DiscoverBody from "@/components/DiscoverBody";
import BlackBox from "@/components/BlackBox";
import Map from "@/components/Map";
export default {
  name: "Discover",
  components: {
    DiscoverHeader,
    DiscoverBody,
    BlackBox,
    Map
  },
  data: () => ({

  })
}
</script>
<style scoped>
</style>
