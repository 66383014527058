import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import SellerGuide from '../views/SellerGuide.vue'
import BuyerGuide from '../views/BuyerGuide.vue'
import Magazine from '../views/Magazine.vue'
import Discover from '../views/Discover.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home

    }, {
        path: '/sellerguide',
        name: 'SellerGuide',
        component: SellerGuide

    },
    {
        path: '/buyerguide',
        name: 'BuyerGuide',
        component: BuyerGuide

  },
  {
    path: '/magazine',
    name: 'Magazine',
    component: Magazine

  },
  {
    path: '/discover',
    name: 'Discover',
    component: Discover

  },
  {
    path: '/PrivacyPolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy

  },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
