<template>
  <div class="navbar navbarText navbarShadow">
      <div class="logo">
    <a @click="$changePage('Home')" >
          <img src="https://api.agencequads.ca/uploads/Chateauguay_Logo_efc8b9b446.png" alt="">
    </a>
      </div>
    <div class="menu-middle">
      <a @click="$changePage('fronteval')" class="nav-item">
        {{ $t("NavBar.Evaluation") }}
        <div class="line"></div>
      </a>
      <a @click="$changePage('BuyerGuide')"  class="nav-item">
        {{ $t("NavBar.Buy") }}
        <div class="line"></div>
      </a>
      <a @click="$changePage('SellerGuide')"  class="nav-item">
        {{ $t("NavBar.Sell") }}
        <div class="line"></div>
      </a>
      <a @click="$changePage('Discover')"  class="nav-item">
        {{ $t("NavBar.Discover") }}
        <div class="line"></div>
      </a>
      <a @click="$changePage('Magazine')"  class="nav-item">
        {{ $t("NavBar.Magazine") }}
        <div class="line"></div>
      </a>
      <div class="menu-right">
        <PhoneButton/>
        <div class="buttonLang">
          <LangButton/>
        </div>
      </div>
    </div>
    <div class="nav-mobile">
      <a class="navButton" href="tel:+14508448444">
        <div class="phoneIcon">
          <b-icon icon="telephone-fill" aria-hidden="true" />
        </div>
      </a>
    <img
        class="menu-btn svgPrimary"
        v-b-toggle.sidebar-navMenu
        src="@/assets/Nav-btn.svg"
      />
    </div>
    <b-sidebar no-header id="sidebar-navMenu" no-enforce-focus bg-variant="transparent" right shadow>
      <template #default="{ hide }">
        <div class="sidebarmenu navbarText">
          <div class="closeIcon" @click="hide"><b-icon icon="x" aria-hidden="true"/></div>
          <a><div class="logo"></div></a>
          <br>
          <PhoneButton color="true" class="btn-sidebar"/>
          <br>
          <a @click="hide; $changePage('fronteval')" class="side-nav-item" v-b-toggle.sidebar-navMenu>
            {{ $t("NavBar.Evaluation") }}
          </a>
          <hr class="side-line">
          <a @click="$changePage('BuyerGuide'); hide;" v-b-toggle.sidebar-navMenu class="side-nav-item">
            {{ $t("NavBar.Buy") }}
          </a>
          <hr class="side-line">
          <a @click="$changePage('SellerGuide'); hide;" v-b-toggle.sidebar-navMenu class="side-nav-item">
            {{ $t("NavBar.Sell") }}
          </a>
          <hr class="side-line">
          <a @click="$changePage('Discover'); hide;" v-b-toggle.sidebar-navMenu class="side-nav-item">
            {{ $t("NavBar.Discover") }}
          </a>
          <hr class="side-line">
          <a @click="$changePage('Magazine'); hide;" v-b-toggle.sidebar-navMenu class="side-nav-item">
            {{ $t("NavBar.Magazine") }}
          </a>
          <div class="buttonLang">
            <LangButton color="true" v-b-toggle.sidebar-navMenu />
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  name: "",
  data: function() {
    return {
      timeout:false,

    }
  },

  methods: {
    onClick(event) {
      if (!event.target.matches('.dropdownText') && !event.target.matches('.dropIcon') && !event.target.matches('.dropbtn')) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains('showDropdown')) {
            openDropdown.classList.remove('showDropdown');
          }
        }
      }
    },
    wait(ms){
      var start = new Date().getTime();
      var end = start;
      while(end < start + ms) {
        end = new Date().getTime();
      }
    },
    toggleDropdown() {
      document.getElementById("myDropdown").classList.remove("showDropdown");
      this.timeout = false;
    },
    showDropdown() {
      document.getElementById("myDropdown").classList.add("showDropdown");

    },

    timeoutOff(){
      this.timeout = true;
    }
  },
  mounted() {
    document.addEventListener('click', this.onClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onClick);
  },
};
</script>

<style scoped>

/* ---- UTILITIES ---- */


.spacing {
  min-width: 120px;
}


/* ---- NAVBAR ---- */

.navbar {
  display: flex;
  position: fixed;
  width: 100vw;
  z-index: 100000;
  /* align-items: center; */
  background-color: rgba(255, 255, 255, 0.3);
  padding: 0px 40px;
  height: 70px;
  backdrop-filter: blur(5px);
}

.menu-middle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: auto;
  height: 70px;
  width: 70%;
}
.menu-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  gap: 30px;
  width: fit-content;
  margin-left: 30px;
}

.nav-item {
  color: var(--secondary-dark-color) !important;
  text-decoration: inherit !important;
  align-items: center !important;
  display: flex !important;
  align-items: flex-start;
  flex-direction: column !important;
  justify-content: flex-end !important;
  min-height: 70px !important;
  /* margin: 0 40px !important; */
  cursor: pointer;
}

.buttonLang {
  min-width:40px ;
}
.nav-mobile {
  display: none;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
}

/* ---- NAVBAR MENU ANIMATION ---- */


.nav-item:hover .line {
  animation: line-fade-in 0.2s linear 0s 1;
  transform-box: fill-box;
  transform-origin: 50% 50%;
  opacity: 1;
}
.nav-item:not(:hover) .line {
  animation: line-fade-out 0.2s linear 0s 1;
  transform-box: fill-box;
  transform-origin: 50% 50%;
  opacity: 0;
}

.line {
  background-color: var(--primary-color);
  border-radius: 0;
  height: 4px;
  margin-top: 20px;
  width: 100%;
  opacity: 0;
  bottom: 0px;
}


/* ---- DROPDOWNS ---- */


.dropdown-item {
  min-width: 332px !important;
}

.dropdown-item:hover, .drop-down-item:active {
  background-color: var(--primary-color) !important;
}

.dropIcon {
  margin-top:6px;
  font-size: 8px !important;
  transform: rotate(180deg);
  color: var(--primary-dark-color);
}

.dropdownText {
  display: flex !important;
  align-items: flex-start;
}

.dropdown-menu {
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15) !important;
}

.closeIcon {
  position: absolute;
  right:20px;
  top:10px;
  font-size: 30px;
  cursor: pointer;
  padding-right: 10px !important;
  color: var(--primary-color);
}




/* ----SIDE BAR MENU---- */





.sidebarmenu {
  background-color: rgba(0, 0, 0, 0.85) !important;
  height: 100vh;
  padding: 30px;
  max-height: none;
  text-align: left;
  z-index: 100000;
  /* backdrop-filter: blur(10px) !important; */
 
}
.btn{
  outline: none !important;
  box-shadow: none !important;
}
.btn:focus,.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

/deep/.b-sidebar{
  max-height: none;
}

.btn-sidebar {
  max-width: 187px;
}

.logo {
  /* width: 300px; */
  width: calc(250px + (350 - 250) * ((100vw - 375px) / (1600 - 375)));;
  max-width: 60%;
  height: auto;
  cursor: pointer;
}
.logo img {
  width: 100%;
  height: 100%;
}
.navbutton {
  display: hidden;
  
}

.side-nav-item {
  margin-top: 30px;
  margin-bottom: 30px;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: inherit;
  align-items: left;
  display: flex;
  flex-direction: column;
  color: var(--white);
}

.side-dropdown-item {
  /* margin-left: 30px; */
  margin-top: 10px;
  padding: 5px 20px;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  text-decoration: inherit;
  text-transform:none;
  color: inherit;
  color: rgba(20, 45, 2, 0.7);
}

.side-dropdown-item:hover {
  background-color: var(--primary-color);
  color: var(--white) !important;
  transition: ease .3s;
}

.side-line {
  margin-top: 0;
  color: var(--primary-color);
}

.dropdown-divider {
  width:82%;
  margin-left: 9%;
}

.nav-item-absolute {
  position: relative;
  width: 100px;
  text-align: left;
}

.drop-down {
  height: 8px;
  right: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.vector {
  height: 15px;
  width: 15px;
  position: relative;
  left: 20px;
  top: 12px;
}

.menu-btn {
  Display: none;
}



.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  padding: 20px 0;
  background-color: var(--white);
  overflow: auto;
  z-index: 1;
  border-radius: 5px;
  border: 1px solid var(--gray);
}

.dropdown-content a {
  min-width: 332px;
  padding: 7px 20px;
  text-decoration: none;
  display: block;
  text-align: left;
  cursor: pointer;
  color: var(--primary-dark-color) !important;
}

.dropdown-content a:hover {
  background-color: var(--primary-color);
  color: var(--white) !important;
  transition: ease .3s;
}

.showDropdown {
  display: block;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center
}

.dropdown-content hr {
  margin: 10px 20px;
}

.phoneIcon{
  display: none;
}

@keyframes line-fade-out {
    0% {
        opacity: 1;
        width: 100%;
        transform: translateX(0px);
    }
    100% {
        opacity: 0;
        width: 1px;
        transform: translateX(0px);
    }
}

@keyframes line-fade-in {
    0% {
        opacity: 0;
        width: 1px;
        transform: translateX(0px);
    }
    100% {
        opacity: 1;
        width: 100%;
        transform: translateX(0px);
    }
}

@keyframes growDown {
  0% {
    transform: scaleY(0)
  }
  80% {
    transform: scaleY(1.1)
  }
  100% {
    transform: scaleY(1)
  }
}

@media (max-width: 1250px) {
  .menu-middle {
    Display: none;
  }
  .nav-mobile {
    display: flex;
    color: var(--white);
  }
  .navbar {
    padding: 0px 47px;
  }

  .nav-button {
    display: none;
  }

  .phoneIcon {
    color: var(--secondary-dark-color);
    border-radius: 100%;
    width: 35px;
    height: 35px;
    background: transparent;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    border: 1px solid;
    border-color: var(--primary-color);
  }

  .menu-btn {
    cursor: pointer;
    display: flex;
    height: 16px;
    right: 47px;
    width: 27px;
  }

}

@media (max-width: 768px) {

  .navbar {
    padding: 0px 20px;
  }
}

</style>
