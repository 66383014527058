import Api from "@/services/api";

const basic = "publicsendmail";
export default {
  post(payload) {
    payload.code = '1aksdhkjHJKGjdhakldhsiouGMNBJHGJ90378hyJVBJHDGJK'
    payload.from = 'noreply@cemiar.com'
    payload.to = "lisalavie@groupemackay.com"
    // payload.to = "acyr@cemiar.com"
    payload.name = "Site web Immobilier Châteauguay"
    return Api.cemiarLink().post("/" + basic, payload);
  },
};
