<template>
  <div class="">
    <b-row class="cards">
      <b-col md="6" order-md="1" class="padding-0">
        <DocCard
          :title="$t('Sguide.Title')"
          :text="$t('Sguide.Text')"
          coverUrl="hide"
          :downloadUrl="$t('Sguide.pdf')"
        />
      </b-col>
      <b-col md="6" order-md="2" class="padding-0">
        <DocCard
          :doc="$t('Sguide.img')"
          empty="hide"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import DocCard from '@/components/DocCard'
export default {
  name: "",
  components: {
    DocCard
  },
  data: () => ({

  })
}
</script>
<style scope>
.sub-title{
  padding: 0px 100px;
  margin-bottom: 80px;
}

.cards{
  margin: 0 !important;
}
.padding-0{
  padding: 0 !important;
}

.img__wrap {
  position: relative;
  width: 100%;
  margin: auto;
  cursor: pointer;
}

.img__description {
  position: absolute;
  top: 0;
  padding: 20%;
  bottom: 0;
  height: 100%;
  left: 0;
  right: 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  visibility: hidden;
  opacity: 0;

  /* transition effect. not necessary */
  transition: opacity .2s, visibility .2s;
}

.img__wrap:hover .img__description {
  visibility: visible;
  opacity: 1;
}

</style>
