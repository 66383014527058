<template>
  <div class="homepage">
  
      <div class="homepage-section">
        <div class="text-box" v-scrollanimation-1  v-animate-onscroll.repeat="'animated fadeIn'">
          <h1 class="text-box1">
            <div class="title-1" v-html="$t('Presentation.Title-1')" >
            </div >
            <div class="title-2">
               {{ $t("Presentation.Title-2") }}
            </div>
          </h1>
          <div class="zone-buttons">
            <a href="tel:+14508448444" class="ContactButton buttonShadow navbarText">
              {{ $t("Presentation.Button") }}
            </a>
          </div>
        </div>
       <div class="form-wrapper">
        <ContactForm :background="true" :text="$t('ContactForm.ContactTitle')" />
       </div>
      </div>
      <a class="downArrow" href="#fronteval"><img width="60px" src="../assets/downArrow.svg" href="#about"></a>
 
  </div>
</template>

<script>
export default {
  name: "App",
  data: function() {
    return {
      menuTitle:{

      },
      form:{
        whenSell:null,

      },
      dismissSecs: 6,
      dismissCountDown: 0
    }
  },
  methods:{
    onSubmit(event) {
      event.preventDefault();
    },
  }
};
</script>
<style scoped>

.homepage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-image: url("https://api.agencequads.ca/uploads/Discover_Chateauguay_header_img_f6a0ddd09e.webp");
  background-position: 50% 50%;
  background-size: cover;
  position: relative;
}

.ContactSelect{
  width: 100%;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  border: 1px solid #cdcdcd;
}
.homepage-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
  padding: 10px;
  margin-bottom: 50px;
}

.text-box {
  width: fit-content;
}
.form-wrapper {
  max-width: 335px;
  
}
.text-box1 {
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 67px;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  color: var(--secondary-dark-color);
  text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  /* position: absolute; */
  /* display: block; */
}
.title-1 {
  text-align: left;
  font-size: calc(30px + (45 - 30) * ((100vw - 375px) / (1600 - 375)));
  max-width: fit-content;  
}
.title-2 {
  text-align: left;
  margin-top: 0px;
  min-width: fit-content;
  max-width: fit-content;  
  max-height: fit-content;  
  font-size: calc(25px + (45 - 25) * ((100vw - 375px) / (1600 - 375)));
  white-space: nowrap;
}
.zone-buttons {
  position: relative;
  margin-left: 0;
  margin-top: 30px;
  margin-bottom: 150px;
}

.ContactButton{
  position: absolute;
  background: var(--white);
  color: var(--primary-dark-color);
  border-radius: 5px;
  padding: 13px 20px 10px 20px;
  border: 0;
  align-items: center;
  display: flex;
}

.ContactButton:hover {
  transform: translateY(-2px);
  transition: all ease-out .3s;
  color: var(--primary-color);
}




.ContactSelect{
  width: 100%;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  border: 1px solid #cdcdcd;
}

.downArrow{
  display: block;
  position: absolute;
  margin: 0 auto;
  bottom: 30px;
  align-items: center;

}

.downArrow:hover {
  transform: translateY(-10px);
  transition: all ease-out .3s;
}


.link1:hover {
 color: var(--primary-color)
}

@-webkit-keyframes conception {
 0% {
  transform: translateX(20px);
 } 
 100% {
  transform: translateX(0px);
 }
}
.animation-1{
  animation: fadeIn 2s ease;
}

@media (max-width: 768px) {
  .homepage-section {
    gap: 20px;
  }
  .text-box {
    margin-bottom: 50px;
  }
  .zone-buttons {
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .title-2 {
    margin-top: 5px;
  }
  .homepage {
    min-width: 375px;
  }
  .downArrow {
    display: none;
  }
}
</style>

