<template>
  <div class="WhiteBox" data-id="207:17">
    <b-container>
      <div class="WhiteBoxList">
        <img class="svgPrimary" src="@/assets/home_work.svg"/>
        <h3 class="text">{{ $t ("WhiteBox.Text") }}</h3>
        <a @click="$changePage('Discover')" class="downloadButton navbarText">
          {{ $t ("Button.Discover") }}
        </a>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "App",
  data: function() {
    return {}
  },
  methods: {
  }
};
</script>

<style >
.WhiteBox {
  align-items: flex-start;
  display: flex;
  min-height: 541px;
}

.WhiteBoxList{
}



</style>
<style scoped>

h3, .text {
  margin: 30px 0px !important;
}

.animation-1{
  animation: moveUp 1s ease-in 0s 1 ;
}
</style>

<style scoped>

.downloadButton{
  display: inline-block;
  align-items: center;
  padding: 10px 20px;
  margin: auto;
  text-align: center;
  cursor: pointer;
  transition: all ease-out .2s;
  /* White */

  border: 1px solid;
  border-radius: 30px;
}
.downloadButton:hover {
  /* transform: scaleX(1.05) scaleY(1.04); */
  transform: scale(1.05);
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.2);
}


</style>
