<template>
  <div class="page">
    <BlackBox />
    <BGuide />
    <WhiteBox />
    <Evaluation />
  </div>
</template>
<script>
import BlackBox from "@/components/BlackBox";
import WhiteBox from "@/components/WhiteBox";
import BGuide from "@/components/BGuide";
import Evaluation from "@/components/Evaluation";
export default {
  name: "",
  components: {
    BlackBox,
    WhiteBox,
    BGuide,
    Evaluation
  },
  data: () => ({

  })
}
</script>
<style scoped>
</style>
