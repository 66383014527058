import axios from "axios";

let backUrl = "https://api.agencequads.ca/";
let cemiarlinkURL = "https://link.cemiar.com/mail/v1/";

//console.log(backUrl);

export default {
    default () {
        const instance = axios.create({
            baseURL: backUrl,
        });

        return instance;
    },
    cemiarLink () {
        const instance = axios.create({
            baseURL: cemiarlinkURL,
        });

        return instance;
    },
};
