<template>
  <div class="BlackBox">
      <div v-if="!customText" class="BlackBoxList" v-scrollanimation-1>
        <h3>
         {{ $t ("BlackBox.Text") }}
        </h3>
      </div>
      <div v-if="customText" class="BlackBoxList" v-scrollanimation-1>
        <h3>
         {{title}}
        </h3>
         <br>
        <p class="paragraph">
          {{text}}
        </p>
      </div>
  </div>
</template>

<script>
export default {
  name: "App",
  props: [
    "customText",
    "title",
    "text",
    "bio"
  ],
  data: function() {
    return {}
  },
  methods: {
  }
};
</script>

<style scoped>
.BlackBox {
  align-items: center;
  display: flex;
  min-height: 420px;
  background: var(--secondary-dark-color);
  padding: 20px;
}

.BlackBoxList{
  color: var(--white);
  max-width: 950px;
  margin: auto;
  text-align: center;
}

</style>
