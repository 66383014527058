<template>
  <div class="page">
    <b-container>
      <h2 class="title">{{$t ('Magazine.Title')}}</h2>
      <p class="paragraphe">{{$t ('Magazine.Description')}}</p>
      <a class="magazineimg" @click="Download">
        <div class="img__wrap">
          <img width="100%" src="https://api.agencequads.ca/uploads/magazine_798569a4d7.webp"/>
          <div class="img__description">
              <img class=" svgPrimary" src="@/assets/PDFVector.svg"/>
              <h3 class="space-top space-bottom" >{{$t ('Magazine.BoxText')}}</h3>
              <div class="downloadButton Navigation-text-btn">
                {{$t ('Button.Download')}}
              </div>
          </div>
        </div>
      </a>
    </b-container>
    <DownloadModal :show="show" :downloadUrl="downloadUrl"/>
  </div>
</template>
<script>
export default {
  name: "",
  data: () => ({
    show:false,
    downloadUrl:'https://api.agencequads.ca/uploads/Screen_Shot_2021_10_15_at_4_22_45_PM_3afe4e1600.pdf'
  }),
  methods: {
    Download() {
      this.show = !this.show
    }
  }
}
</script>
<style scoped>
  .title{
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .magazineimg{
    margin-top: 80px;
    margin-bottom: 80px;
    padding: 0 200px;
  }


  .img__wrap {
    position: relative;
    max-width: 800px;
    width: 100%;
    margin: auto;
    cursor: pointer;
    padding: 40px;
    border: 2px solid #D6AD7F;
    box-sizing: border-box;
    border-radius: 3px;
  }



  .img__description {
    position: absolute;
    top: 0;
    padding: 50% 0;
    bottom: 0;
    height: 100%;
    left: 0;
    right: 0;
    text-align: center;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    visibility: hidden;
    opacity: 0;
    backdrop-filter: blur(10px);

    /* transition effect. not necessary */
    transition: opacity .2s, visibility .2s;
  }

  .img__wrap:hover .img__description {
    visibility: visible;
    opacity: 1;
    
  }

  .space-top {
    margin-top: 60px;
}

  .space-bottom {
    margin-bottom: 60px;
}


  @media (max-width: 1024px) {
    .magazineimg{
      margin-top: 80px;
      margin-bottom: 80px;
      padding: 0 100px;
    }
  }

  @media (max-width: 750px) {
    .magazineimg{
      margin-top: 80px;
      margin-bottom: 80px;
      padding: 0;
    }

      .img__wrap {
        padding: 10px;
      }

      .title{
      margin-top: 20px;
      margin-bottom: 10px;
  }
  }
</style>
